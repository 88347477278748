import { jsx } from "react/jsx-runtime";
import { createComponentStore } from "@tecnobit-srl/components-store";
const spinnerContainerStyle = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
const {
  Component: InnerRoute,
  ComponentStoreProvider: RouteTypesProvider,
  useAddComponent: useAddRouteType
} = createComponentStore("route-types", /* @__PURE__ */ jsx("div", { style: spinnerContainerStyle }));
function Route(props) {
  return /* @__PURE__ */ jsx(
    InnerRoute,
    {
      id: props.type,
      p: {
        route: props
      }
    }
  );
}
export {
  Route,
  RouteTypesProvider,
  useAddRouteType
};
